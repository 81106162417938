<template>
  <div class="mb-3">
    <checkbox
        :fieldAttrInput="{}"
        :fieldAttributes=" { name:'Transcribe Voice from Audio' }"
        :value="value.stt"
        @input="updateCheck('stt',...arguments)"
        :hideDetails="true"
    ></checkbox>
  </div>
</template>
<script>
import checkbox from '@/commonComponents/checkbox.vue';

export default {
  components: {
    checkbox
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  methods: {
    updateCheck(index, value) {
      let newVal = false;
      if (value == 1) {
        newVal = true;
      }
      let localValue = {...this.value};
      this.$set(localValue, index, newVal);
      this.$emit('input', localValue);
    },
  },
};
</script>